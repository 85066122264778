import { createURLComponent } from './helpers';
import urlPrefixes from '@/constants/urlPrefixes';

/**
 * create attributes part (SEO friendly part)
 * @param attributes - array of attributes
 * @returns attributes part
 *
 * expected output: /f/${attrValueLabel1}+${attrValueLabel2}/${attrValueId1}+${attrValueId2}
 * p.s. must include max 2 attributes (for more details check: getSortedAttributes function)
 * includes Alphabetically order logic
 */
const createAttributesPart = (attributes: Record<string, any>[] = []): string => {
  if (Array.isArray(attributes) && attributes.length) {
    const labels = attributes
      .map((attr) => createURLComponent(attr.attributeValueLabel))
      .join(urlPrefixes.seoFriendlyMergeKey);
    const ids = attributes.map((attr) => attr.attributeValueId).join(urlPrefixes.seoFriendlyMergeKey);
    return `/${urlPrefixes.attributes}/${labels}/${ids}`;
  }
  return '';
};

export default createAttributesPart;
