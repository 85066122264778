const getCategoryOptions = (categoryOptions = [] as TSearchCategoryOption[]) =>
  categoryOptions.map((option) => ({ label: option.fullName, value: option.id }));

export default function enrichSearchBarConfig(props = {} as EnrichSearchBarConfigProps) {
  const {
    searchCategoryId,
    searchCategoryOptions,
    searchRequestObject: { distance, searchQuery, seller },
    searchBar: {
      queryValue,
      categoryValue,
      categoryOptions,
      postcodeValue,
      distanceValue,
      distanceOptions,
      hasSearchOnPage,
      searchOnPageValue,
      recentQueryUrl,
      recentClearUrl,
      suggestionsUrl,
      translations,
      savedSearchesUrl,
      searchBarTranslationsForTenant,
    },
  } = props;

  return {
    queryValue: searchQuery || queryValue,
    categoryValue: searchCategoryId.toString() || categoryValue.toString(),
    categoryOptions: [...categoryOptions, ...getCategoryOptions(searchCategoryOptions)],
    postcodeValue: distance?.postcode || postcodeValue,
    distanceValue: distance?.distanceMeters?.toString() || distanceValue.toString(),
    distanceOptions,
    hasSearchOnTitleAndDescription: !!searchQuery,
    hasSearchOnPage: !!seller || hasSearchOnPage,
    searchOnPageValue,
    recentQueryUrl,
    recentClearUrl,
    suggestionsUrl,
    savedSearchesUrl,
    translations,
    searchBarTranslationsForTenant,
  };
}
