interface Attribute {
  attributeValueKey: string;
  [key: string]: any;
}

interface MergeAttributesParams {
  attributes?: Record<string, Attribute[]>;
  newAttribute?: Record<string, Attribute>;
  isSingleSelect?: boolean;
  isSelected?: boolean;
}

/**
 * merge attributes from searchRequest with new attribute
 * @param {object} obj
 * @param {object} obj.attributes
 * @param {object} obj.newAttribute
 * @param {boolean} obj.isSingleSelect
 * @param {boolean} obj.isSelected
 * @returns {{}}
 */
const mergeAttributes = ({
  attributes = {},
  newAttribute = {},
  isSingleSelect = false,
  isSelected = false,
}: MergeAttributesParams): Record<string, Attribute[]> => {
  // if there is nothing as new Attribute
  if (!newAttribute || typeof newAttribute !== 'object') {
    return attributes;
  }

  const mergedObject = { ...attributes };
  const keys = Object.keys(newAttribute);

  keys.forEach((key) => {
    mergedObject[key] = attributes[key] ? attributes[key].concat([newAttribute[key]]) : [newAttribute[key]];
  });

  if (isSingleSelect) {
    keys.forEach((key) => {
      mergedObject[key] = [newAttribute[key]];
    });

    return mergedObject;
  }

  if (isSelected) {
    keys.forEach((key) => {
      if (attributes[key]) {
        mergedObject[key] = attributes[key].filter(
          (item) => item.attributeValueKey !== newAttribute[key].attributeValueKey,
        );
      }
    });

    return mergedObject;
  }

  return mergedObject;
};

export default mergeAttributes;
