import urlPrefixes from '@/constants/urlPrefixes';

/**
 *  create saved search attributes object can be used as part of the URL hash
 * @param {object} obj
 * @param {array} obj.attributesByKey as part of the original search request
 * @returns {object} saved search attributes object
 */
type AttributesByKey = {
  attributesByKey: { attributeKey: string; attributeValueKey: string }[];
};

const createSavedSearchAttrs = ({ attributesByKey }: AttributesByKey) => {
  const savedSearchAttrs = { [urlPrefixes.asSavedSearch]: 'true' };
  const offeredSince = attributesByKey && attributesByKey.find((attr) => attr.attributeKey === 'offeredSince');
  if (offeredSince) {
    savedSearchAttrs[urlPrefixes.offeredSince] = offeredSince.attributeValueKey;
  }
  return savedSearchAttrs;
};

export default createSavedSearchAttrs;
