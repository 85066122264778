import formatPostCode from '@/utils/urlHash/formatPostCode';
import SearchBarDefaults from '@/constants/SearchBar';
import getSortedAttributes from './getSortedAttributes';
import mergeAttributes from './mergeAttributes';
import createLinks from './createLinks';
import CATEGORIES_L1 from '@/constants/categories';

interface LinkObjectOptions {
  searchRequestObject?: TSearchRequestObject;
  newAttribute?: any;
  rangeAttributes?: any;
  isSingleSelect?: boolean;
  isSelected?: boolean;
  removeAllFilters?: boolean;
  removeAllAttributes?: boolean;
  removeAllOtherFilters?: boolean;
  categories?: TCategories;
  page?: number;
  sortOptions?: any;
  postcode?: string;
  distance?: {
    postcode?: string;
    distance?: number;
    distanceMeters?: number;
  };
  seller?: TSeller;
  view?: string;
  query?: string;
  pageSize?: number;
  searchInTitleAndDescription?: boolean;
  attributes?: any;
  allowSavedSearch?: boolean;
  bypassSpellingSuggestion?: boolean;
  isViewOptionsClicked?: boolean;
  isSuggestedSearch?: boolean;
  categoryRedirectedClientSide?: boolean;
  disableRedirect?: boolean;
  withAllAttributes?: boolean;
  isSortingEnabled?: boolean;
}

// Helper function to update search request object category data
const fromSearchRequestCategoryObject = ({ l1Category, l2Category }: TCategories = {}) => {
  return {
    l1Category,
    l2Category,
  };
};

// Generates the link object based on various parameters
export const generateLinkObject = ({
  searchRequestObject,
  newAttribute = {},
  rangeAttributes,
  isSingleSelect = false,
  isSelected = false,
  removeAllFilters = false,
  removeAllAttributes = false,
  removeAllOtherFilters = false,
  categories,
  page = 1,
  sortOptions,
  postcode,
  distance,
  seller,
  view,
  query,
  pageSize,
  searchInTitleAndDescription,
  attributes,
  allowSavedSearch,
  bypassSpellingSuggestion,
  isViewOptionsClicked = false,
  isSuggestedSearch = false,
  categoryRedirectedClientSide = false,
  disableRedirect,
  withAllAttributes,
  isSortingEnabled,
}: LinkObjectOptions) => {
  const {
    distance: sroDistance,
    searchInTitleAndDescription: sroSearchInTitleAndDescription,
    seller: sroSeller,
    searchQuery: sroSearchQuery,
    attributes: sroAttributes,
    categories: sroCategories,
    sortOptions: sroSortOptions,
    attributeRanges: sroAttributeRanges,
    viewOptions: sroViewOptions,
    traits: sroTraits,
  } = searchRequestObject ?? {};

  const getDistance = () =>
    distance ??
    (distance === SearchBarDefaults.DISTANCE ? distance : (sroDistance as { distanceMeters?: number })?.distanceMeters);

  const getPostcode = () => postcode ?? (postcode === SearchBarDefaults.POSTCODE ? postcode : sroDistance?.postcode);

  const distanceMeters = getDistance() as number;
  const requestPostcode = getPostcode();
  const inDescription = searchInTitleAndDescription ?? sroSearchInTitleAndDescription;

  const linkCategories = categories || fromSearchRequestCategoryObject(sroCategories);
  const { l1Category } = linkCategories;
  const showQueryInSeoFriendlyPart = removeAllFilters || !l1Category || isSuggestedSearch;

  const getQuery = () => (isSuggestedSearch ? query : (query ?? sroSearchQuery));
  const isWomenClothingCategory = searchRequestObject?.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN;
  const withAttributesSorting = isSortingEnabled && !isWomenClothingCategory;

  const combinedAttributes =
    attributes ||
    mergeAttributes({
      attributes: sroAttributes,
      newAttribute,
      isSingleSelect,
      isSelected,
    });

  const prioritizedAttributes = getSortedAttributes(
    combinedAttributes,
    searchRequestObject?.seoFriendlyAttributes,
    searchRequestObject?.seoFriendlyTextAttributes,
    withAllAttributes,
    withAttributesSorting,
  );

  return {
    searchRequestObject,
    seller: seller || sroSeller,
    query: getQuery(),
    searchInTitleAndDescription: inDescription,
    attributes: combinedAttributes,
    categories: linkCategories,
    page,
    pageSize,
    sortOptions: sortOptions || sroSortOptions,
    rangeAttributes: rangeAttributes || sroAttributeRanges,
    postcode: requestPostcode ? formatPostCode(requestPostcode) : undefined,
    distance: distanceMeters,
    view: view || sroViewOptions?.kind,
    removeAllFilters,
    removeAllAttributes,
    removeAllOtherFilters,
    allowSavedSearch,
    bypassSpellingSuggestion,
    isViewOptionsClicked,
    showQueryInSeoFriendlyPart,
    traits: sroTraits,
    categoryRedirectedClientSide,
    disableRedirect,
    prioritizedAttributes,
  };
};

export const linkGeneratorHelper = (opts) => createLinks(generateLinkObject(opts));
