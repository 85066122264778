const getAttributeKeyValuePair = ({
  from,
  to,
  attributeKey,
}: {
  from: string | number;
  to: string | number;
  attributeKey: string;
}) => {
  const part = {};
  if (typeof from !== 'undefined' && from !== null) {
    part[`${attributeKey}From`] = from;
  }
  if (typeof to !== 'undefined' && to !== null) {
    part[`${attributeKey}To`] = to;
  }
  return part;
};

export default getAttributeKeyValuePair;
