import urlPrefixes from '@/constants/urlPrefixes';
import formatSearchQueryFromUrl from '@/utils/formatters/formatForURL';
import createTextAttributesPart from './createTextAttributesPart';
import createSortOptionsPart from './createSortOptionsPart';
import getAttributeKeyValuePair from './getAttributeKeyValuePair';
import createHashElement from './createHashElement';
import createSavedSearchAttrs from './createSavedSearchAttrs';
import { createSellerPart } from './createSellerLink';
import createCategoriesPart from './createCategoriesPart';
import createAttributesPart from './createAttributesPart';
import {
  createPageIndexPart,
  createQueryPart,
  includeViewOptions,
  isNotEmpty,
  isValidPageSize,
  isValidString,
} from './helpers';

/**
 * generate links according to ADR document: https://github.mpi-internal.com/bnl/vostok-adr/blob/master/adr-033-seo.md
 */
const createLinks = ({
  searchRequestObject,
  seller,
  categories,
  query,
  page,
  pageSize,
  sortOptions,
  rangeAttributes = [],
  distance = 0,
  postcode = '',
  view,
  removeAllFilters,
  removeAllAttributes,
  removeAllOtherFilters,
  allowSavedSearch,
  isViewOptionsClicked,
  bypassSpellingSuggestion,
  showQueryInSeoFriendlyPart,
  traits,
  disableRedirect,
  prioritizedAttributes,
}): string => {
  const keepAttributes = !removeAllFilters && !removeAllAttributes;

  const sellerUrl = createSellerPart(seller);
  const filteredCategories = removeAllOtherFilters ? { ...categories.l1Category } : categories;
  const categoriesUrl = !removeAllFilters ? createCategoriesPart(filteredCategories) : '';
  const attributesUrl = keepAttributes ? createAttributesPart(prioritizedAttributes.seoFriendlyAttributes) : '';
  const textAttributesUrl = keepAttributes
    ? createTextAttributesPart(prioritizedAttributes.seoFriendlyTextAttributes)
    : '';
  const queryUrl = showQueryInSeoFriendlyPart ? createQueryPart(query) : '';
  const pageUrl = createPageIndexPart(page);
  const disableRedirectUrl =
    showQueryInSeoFriendlyPart && isValidString(query) && disableRedirect ? `?${urlPrefixes.disableRedirect}` : '';

  const savedSearch = allowSavedSearch && searchRequestObject && searchRequestObject.asSavedSearch;
  const sortOptionsUrl = createSortOptionsPart(sortOptions);
  const rangeAttributesUrl = rangeAttributes.map(getAttributeKeyValuePair);

  const hashedAttributesUrl = createHashElement({
    ...(!showQueryInSeoFriendlyPart &&
      isValidString(query) && {
        [urlPrefixes.query]: formatSearchQueryFromUrl(query),
      }),
    ...(keepAttributes && prioritizedAttributes.hashFriendlyAttributes),
    ...(keepAttributes && Object.assign({}, ...rangeAttributesUrl)),
    ...(keepAttributes && sortOptionsUrl),
    ...(!removeAllFilters && Number(distance) > 0 && { [urlPrefixes.distance]: distance }),
    ...(!removeAllFilters && isValidString(postcode) && { [urlPrefixes.postcode]: postcode.toUpperCase() }),
    ...((!removeAllFilters || query) && includeViewOptions(view, isViewOptionsClicked) && { [urlPrefixes.view]: view }),
    ...(isValidPageSize(pageSize) && { [urlPrefixes.Limit]: pageSize }),
    ...(keepAttributes && savedSearch && createSavedSearchAttrs(searchRequestObject)),
    ...(bypassSpellingSuggestion && { [urlPrefixes.bypassSpellingSuggestion]: true }),
    ...(!removeAllFilters && isNotEmpty(traits) && { [urlPrefixes.traits]: traits }),
  });
  const hashedUrl = hashedAttributesUrl ? `${urlPrefixes.nonTrackingKey}${hashedAttributesUrl}` : '';

  return `${sellerUrl}${categoriesUrl}${queryUrl}${attributesUrl}${textAttributesUrl}${pageUrl}/${disableRedirectUrl}${hashedUrl}`;
};

export default createLinks;
