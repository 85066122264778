import urlPrefixes from '@/constants/urlPrefixes';
import { isValidString } from '@/utils/links/helpers';

/**
 * create categories part for link generator
 * @param l1Category - first level category
 * @param l2Category - second level category
 * @returns categories part
 *
 * expected output: l/${l1-cat-name} || l/${l1-cat-name}/${l2-cat-name}
 */
const createCategoriesPart = ({
  l1Category,
  l2Category,
}: {
  l1Category?: { key: string };
  l2Category?: { key: string };
} = {}) => {
  const isL1CategoryValid = l1Category && isValidString(l1Category.key);
  const isL2CategoryValid = l2Category && isValidString(l2Category.key);
  const isValid = isL1CategoryValid || isL2CategoryValid;

  let part = isValid ? `/${urlPrefixes.baseCategoriesURL}` : '';
  part += isL1CategoryValid ? `/${l1Category.key}` : '';
  part += isL2CategoryValid ? `/${l2Category.key}` : '';

  return part;
};

export default createCategoriesPart;
