import urlPrefixes from '@/constants/urlPrefixes';

/**
 * create hashed attributes part
 * @param hashedFriendlyAttributes - hashed friendly attributes
 * @returns {string} hash element
 *
 * expected output: {key1}:{value1},{value2}|{key2}:{value3}
 * Example: #f:10898,10899|PriceCentsTo:150000
 */
const createHashElement = (hashedFriendlyAttributes = {}) => {
  const keys = Object.keys(hashedFriendlyAttributes);

  return keys
    .map((key) => {
      if (key === urlPrefixes.textAttributes) {
        const textAttributesHashedPart = Object.keys(hashedFriendlyAttributes['ta'])
          .map((textAttributeKey) => {
            const attrValues = hashedFriendlyAttributes['ta'][textAttributeKey];
            const values = Array.isArray(attrValues) ? attrValues.join(',') : attrValues;
            return `${textAttributeKey}=${values}`;
          })
          .join(urlPrefixes.textAttributesSeparator);
        return `${urlPrefixes.textAttributes}:${textAttributesHashedPart}`;
      }
      const attrValues = hashedFriendlyAttributes[key];
      const values = Array.isArray(attrValues) ? attrValues.join(',') : attrValues;
      return `${key}${urlPrefixes.nonTrackingDefiner}${values}`;
    })
    .join(urlPrefixes.nonTrackingSeparator);
};

export default createHashElement;
