import md5 from 'blueimp-md5';
import { seoUrlPart } from '@ecg-marktplaats/aurora-js-formatters';

import formatSearchQueryFromUrl from '@/utils/formatters/formatForURL';
import urlPrefixes from '@/constants/urlPrefixes';

// Validation functions
export const isValidString = (key: string | null): boolean => typeof key === 'string' && key !== '' && key !== null;
export const isValidPageSize = (pageSize: string | number) => pageSize && Number(pageSize) > 0; // disable default pageSize
export const isNotEmpty = (arr: (string | any)[]): boolean => arr && Array.isArray(arr) && arr.length > 0;

// URL creation functions
export const createURLComponent = (urlPart: string | number): string => seoUrlPart(urlPart).toLowerCase();
export const createPageIndexPart = (page: number): string => (Number(page) > 1 ? `/${urlPrefixes.page}/${page}` : '');
export const createQueryPart = (query: string = ''): string =>
  isValidString(query) ? `/${urlPrefixes.query}/${formatSearchQueryFromUrl(query)}` : '';

// Other functions
export const hashPageName = ({ name, secret }: { name: string; secret: string }): string =>
  md5(`${name}{${secret}}`, null, false);
export const includeViewOptions = (view: string | null, isViewOptionsClicked: boolean): boolean =>
  isValidString(view) && (isViewOptionsClicked || view === 'gallery-view');
