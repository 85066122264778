import urlPrefixes from '@/constants/urlPrefixes';
import { createURLComponent, isValidString } from './helpers';

type TSeller = {
  id: TSellerInformation['sellerId'];
  name: TSellerInformation['sellerName'];
};

export const createSellerNameComponent = (sellerName: string): string => {
  const normalized = createURLComponent(sellerName);
  return normalized || 'verkoper'; // TODO translation
};

export const createSellerPart = (seller: TSeller): string =>
  seller && Number(seller.id) > 0 && isValidString(seller.name)
    ? `/${urlPrefixes.seller}/${createSellerNameComponent(seller.name)}/${seller.id}`
    : '';

export const sellerUrlGenerator = ({ id, name }: TSeller): string => `${createSellerPart({ id, name })}/`;
