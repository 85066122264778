import { attributeKeys } from '@/constants/attributes/nonStandards';
import urlPrefixes from '@/constants/urlPrefixes';

/**
 * create seo friendly text attributes part
 * @param attributes - text attributes
 * @returns {string} - seo friendly text attributes part
 */
const createTextAttributesPart = (attributes: object = {}): string => {
  const textAttributeKeys = Object.keys(attributes);
  if (!textAttributeKeys.length) {
    return '';
  }
  const keys = textAttributeKeys.join(urlPrefixes.seoFriendlyMergeKey);
  const labels = textAttributeKeys
    .map((key) =>
      [attributeKeys.LANGUAGE, attributeKeys.OFFERED_SINCE].includes(key)
        ? attributes[key].attributeValueKey
        : attributes[key].attributeValueLabel,
    )
    .join(urlPrefixes.seoFriendlyMergeKey);

  return `/${urlPrefixes.textAttributes}/${keys}/${labels}`;
};

export default createTextAttributesPart;
