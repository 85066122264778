import sortAttributeNames from '@/constants/attributes/sort';

/**
 * create sortOptions part of hash url
 */
const createSortOptionsPart = (sortOptions: { sortBy?: string; sortOrder?: string; sortAttribute?: string } = {}) => {
  const { sortBy, sortOrder, sortAttribute } = sortOptions;
  const { SORT_BY, SORT_ORDER, SORT_ATTRIBUTE } = sortAttributeNames;

  return {
    ...(sortBy && { [SORT_BY]: sortBy }),
    ...(sortOrder && { [SORT_ORDER]: sortOrder }),
    ...(sortAttribute && { [SORT_ATTRIBUTE]: sortAttribute }),
  };
};

export default createSortOptionsPart;
