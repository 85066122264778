import urlPrefixes from '@/constants/urlPrefixes';
import { maxSeoAttributes } from '@/constants/attributes/standards';
import { defaults as specialAttributesDefaults, attributeKeys } from '@/constants/attributes/nonStandards';

/**
 @param {object} attributes - expected param is mergedAttributes for link Generation
 * @param {Array} attributesOrder
 * @param {object} textAttributesOrder
 * @param {boolean} withAllAttributes
 * @param {boolean} isSortingEnabled
 * @returns {{seoFriendlyAttributes: {}, hashFriendlyAttributes: {}, seoFriendlyTextAttributes: {}}}
 * 
 * seoFriendlyAttributes: (max item 2), (has always first element of seoFriendly attributes)
 * hashFriendlyAttributes: (all hash friendly attributes) + (rest of the seo Friendly attributes)
 * seoFriendlyTextAttributes: (max 1 item), (has always first element of seoFriendly text attributes)
 */

const getSortedAttributes = (
  attributes: TAttribute[],
  attributesOrder: string[] = [],
  textAttributesOrder: Record<string, any> = {},
  withAllAttributes?: boolean,
  withAttributesSorting?: boolean,
) => {
  const seoFriendlyAttributes: string[] = [];
  const hashFriendlyAttributes = {};
  const seoFriendlyTextAttributes = {};
  const seoFriendlyTextAttributesRequestObject = {};

  const pushValueKey = (attributeKey, attributeValue) => {
    if (Object.values(attributeKeys).indexOf(attributeKey) > -1) {
      if (hashFriendlyAttributes[attributeKey]) {
        hashFriendlyAttributes[attributeKey].push(encodeURIComponent(attributeValue.attributeValueKey));
      } else {
        hashFriendlyAttributes[attributeKey] = [encodeURIComponent(attributeValue.attributeValueKey)];
      }
    } else {
      if (hashFriendlyAttributes[urlPrefixes.textAttributes]) {
        if (hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey]) {
          hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey].push(
            encodeURIComponent(attributeValue.attributeValueKey),
          );
        } else {
          hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey] = [
            encodeURIComponent(attributeValue.attributeValueKey),
          ];
        }
      } else {
        hashFriendlyAttributes[urlPrefixes.textAttributes] = {};
        hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey] = [
          encodeURIComponent(attributeValue.attributeValueKey),
        ];
      }
    }
  };

  const pushValueId = (attributeValue) => {
    const attributeKey = urlPrefixes.attributes;
    if (hashFriendlyAttributes[attributeKey]) {
      hashFriendlyAttributes[attributeKey].push(attributeValue.attributeValueId);
    } else {
      hashFriendlyAttributes[attributeKey] = [attributeValue.attributeValueId];
    }
  };

  const seoFriendlyKeys = Object.keys(attributes).map((key) => attributesOrder.indexOf(key) > -1 && key);

  const textAttributeOrderKeys = Object.keys(textAttributesOrder);
  const seoFriendlyTextAttributeKeys = Object.keys(attributes).filter(
    (key) => textAttributeOrderKeys.indexOf(key) > -1,
  );

  Object.keys(attributes)
    .sort((a, b) => attributesOrder.indexOf(a) - attributesOrder.indexOf(b))
    .filter((key) => attributes[key].length)
    .forEach((key) => {
      if (withAttributesSorting) {
        attributes[key].sort(
          (a: { attributeValueId: number }, b: { attributeValueId: number }) => a.attributeValueId - b.attributeValueId,
        );
      }
      if (withAllAttributes) {
        if (seoFriendlyTextAttributeKeys.indexOf(key) > -1 && Object.keys(seoFriendlyTextAttributes).length < 1) {
          seoFriendlyTextAttributes[key] = attributes[key][0];
          seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
          attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
        } else {
          attributes[key].forEach((attr) => {
            if (attr.attributeValueId) {
              seoFriendlyAttributes.push(attr);
            } else if (!attr.isDefault || key === attributeKeys.LANGUAGE) {
              seoFriendlyTextAttributes[key] = attributes[key][0];
              seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
              attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
            }
          });
        }
        return;
      }

      if (seoFriendlyKeys.indexOf(key) > -1 && Object.keys(seoFriendlyAttributes).length < maxSeoAttributes) {
        seoFriendlyAttributes.push(attributes[key][0]);
        attributes[key].slice(1, attributes[key].length).forEach(pushValueId);
      } else if (attributes[key][0].attributeValueId) {
        attributes[key].forEach(pushValueId);
      } else if (specialAttributesDefaults.attributes.indexOf(attributes[key][0].attributeValueKey) === -1) {
        if (seoFriendlyTextAttributeKeys.indexOf(key) > -1 && Object.keys(seoFriendlyTextAttributes).length < 1) {
          seoFriendlyTextAttributes[textAttributesOrder[key]] = attributes[key][0];
          seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
          attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
        } else {
          attributes[key].forEach((value) => pushValueKey(key, value));
        }
      }
    });

  return {
    seoFriendlyAttributes,
    hashFriendlyAttributes,
    seoFriendlyTextAttributes,
    seoFriendlyTextAttributesRequestObject,
  };
};

export default getSortedAttributes;
